// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui-pro/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-quill/dist/quill.snow.css';

@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';
