// Here you can add other styles
$whiteColor: white;
$blackColor: black;
$lightGray: #a4a4a4;
$fontFamily: 'Inter';
$primaryColor: #003080;

@font-face {
  font-family: 'Coolvetica';
  src: url('../assets/fonts/coolvetica-rg.otf') format('opentype');
}

@font-face {
  font-family: 'Coolvetica Condensed';
  src: url('../assets/fonts/coolvetica-condensed-rg.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../assets/fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../assets/fonts/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: 700;
}

@import url('https://fonts.googleapis.com/css2?family=Epilogue&family=Lexend&family=Inter:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root {
  --font-family-main: 'Coolvetica';
  --font-family-secondary: 'SF Pro Display';
  --success-100: #baedcd;
  --success-700: #188c43;
  --warning-100: #f8e7b2;
  --warning-700: #a67f06;
  --danger-100: #fac5c5;
  --danger-700: #aa3030;
  --border-color: #d4d4d4;
}

body {
  font-family: $fontFamily !important;
  background: white !important;
}

.sidebar {
  background-color: $whiteColor !important;
  color: black !important;
}

.sidebar-nav .nav-title {
  color: $lightGray !important;
  font-weight: 400 !important;
  margin-top: 0px !important;
}

.sidebar-nav .nav-link {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-size: 14px;
}

.sidebar-nav .nav-item a,
.sidebar-nav .nav-group a,
.sidebar-nav .nav-group.show a {
  color: $blackColor !important;
}

.sidebar-nav svg {
  color: $blackColor !important;
}

.sidebar-nav .nav-item {
  padding-left: 27px;
  padding-right: 27px;
}

.sidebar-nav .nav-group .nav-group-items .nav-item {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.sidebar-nav .nav-item .nav-link:hover,
.sidebar-nav .nav-group .nav-link:hover {
  background: #f4f4f4;
  border-radius: 5px;

  img {
    border-left: 4px solid $blackColor !important;
    padding-left: 6px;
  }
}

.sidebar-nav .nav-item .nav-link.active,
.sidebar-nav .nav-group .nav-link.active {
  background: #f4f4f4;
  border-radius: 5px;

  img {
    border-left: 4px solid $blackColor !important;
    padding-left: 4px;
  }
}

.sidebar-nav .nav-group-toggle:hover {
  margin-left: 27px !important;
  margin-left: 27px;
  padding-right: 27px;

  &::after {
    margin-left: -17px !important;
  }
}

.sidebar-nav .nav-group.show {
  background: #f4f4f4;
}

.sidebar-nav .nav-group-toggle::after {
  background-image: url('../assets/svg/arrowRigth.svg') !important;
  width: 100px;
  order: -1 !important;
  margin-left: 10px !important;
  margin-right: 5px !important;
}

.sidebar-nav .nav-icon {
  padding-right: 0px !important;
}

.sidebar-nav .nav-icon {
  flex: 0 0 3rem !important;
  padding-left: 10px;
}

.sidebar-nav .nav-group.show {
  background: none !important;
}

.sidebar-nav .nav-group.show>.nav-group-toggle::after {
  transform: rotate(90deg) !important;
}

.header #search-box {
  width: 120px;
  background: #f4f4f4;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 300;
  font-size: 15px;
  color: $lightGray;
  font-family: 'Inter';
}

.header #search-box:focus {
  outline: none !important;
  border-color: transparent;
}

.header #search-icon {
  background: #f4f4f4;
  border: 0px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.header #search-icon-2 {
  background: #f4f4f4;
  border: 0px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-right: 5px;
}

.header.header-sticky {
  box-shadow: none;
  border-bottom: 1px solid #eee !important;
  box-shadow: none !important;
}

.font-fam-coolvetica {
  font-family: 'Coolvetica', sans-serif;
}

.font-fam-epilogue {
  font-family: 'Epilogue', sans-serif;
}

.font-fam-sfprodisplay {
  font-family: 'SF Pro Display', sans-serif;
}

.font-fam-inter {
  font-family: 'Inter', sans-serif;
}

.remove-outline:focus {
  outline: none;
}

.z-index-100 {
  z-index: 1030;
}

.wrapper {
  background: $whiteColor !important;
}

.widget-card-skyblue {
  background-color: #e3f5ff;
  border-radius: 10px;
  padding: 25px 15px 25px 15px;
}

.widget-card-light-violet {
  background-color: #e5ecf6;
  border-radius: 10px;
  padding: 25px 15px 25px 15px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #a8c5da;
  border-radius: 50%;
  display: inline-block;
}

.dot-black {
  height: 8px;
  width: 8px;
  background-color: $blackColor;
  border-radius: 50%;
  display: inline-block;
}

.dot-success {
  height: 6px;
  width: 6px;
  background-color: #14ba6d;
  border-radius: 50%;
  display: inline-block;
}

.dot-secondary {
  height: 6px;
  width: 6px;
  background-color: #6c778b;
  border-radius: 50%;
  display: inline-block;
}

.dot-warning {
  height: 6px;
  width: 6px;
  background-color: #ffeb3b;
  border-radius: 50%;
  display: inline-block;
}

.dot-danger {
  height: 6px;
  width: 6px;
  background-color: #9c0000;
  border-radius: 50%;
  display: inline-block;
}

.bg-light-gray {
  background-color: #f7f9fb;
}

.bg-blue {
  background-color: #279fff;
}

.bg-light-blue {
  background-color: #f6faff;
}

.text-blue {
  color: #279fff;
}

.text-foundation-black {
  color: #202124;
}

.bg-main-primary {
  background-color: $primaryColor !important;
}

.revenue-breakdown .form-check-input {
  margin-left: 15px;
  margin-right: 15px;
}

.login-credential-label {
  color: #3a3c3f;
  font-family: 'Coolvetica', sans-serif;
  font-size: 12px;
  // font-style: normal;
  // font-weight: 600;
  // line-height: normal;
  letter-spacing: 0.24px;
}

.input-login {
  height: 56px;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  color: #929da9;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.input-login.number {
  text-align: center;
  color: var(--cui-primary);
  font-family: Inter;
  font-size: 37.647px;
  font-style: normal;
  font-weight: 500;
  line-height: 47.059px;
  /* 125% */
  letter-spacing: -0.753px;
  border-radius: 6.275px;
  border: 1.569px solid var(--cui-primary);
  background: var(--base-white, #fff);
  box-shadow: 0px 0.7843137383460999px 1.5686274766921997px 0px rgba(16, 24, 40, 0.05);
}

@media (min-width: 576px) {
  .forgot-password-modal .modal-dialog {
    max-width: 360px !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.login-submit-button {
  color: var(--white, #fff);
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  border-radius: 8px;
  background: var(--cui-primary);
  display: flex;
  width: 332px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.login-submit-button-disable {
  color: var(--white, #fff);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.16px;
  border-radius: 8px;
  background: var(--gray, #d1d1d1);
  display: flex;
  width: 332px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0px solid !important;
}

.donate-now-button {
  border-radius: 8px;
  border: 1px solid var(--cui-primary);
  display: flex;
  width: 177px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--cui-primary);
  font-family: 'Coolvetica', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
}

.donor-btn-edit {
  color: var(--cui-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  border-radius: 8px;
  border: 1px solid var(--cui-primary);
  height: 40px;
  gap: 8px;
  align-self: stretch;
  background: white;
}

.donor-profile-label-success {
  border-radius: 16px;
  background: #ecfdf3;
  color: #037847;
  padding: 8px 16px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.donor-profile-label-primary {
  border-radius: 16px;
  background: #e2edff;
  color: var(--cui-primary);
  padding: 8px 16px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.donor-btn-view-user {
  border-radius: 8px;
  background: var(--cui-primary);
  width: 146px;
  height: 40px;
  gap: 8px;
  color: var(--white, #fff);
  font-family: 'Coolvetica', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  border: none;
}

.donor-btn-disconnect-user {
  border-radius: 8px;
  border: 1px solid var(--danger-500, #f43f5e);
  display: flex;
  width: 146px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--danger-500, #f43f5e);
  font-family: Coolvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  background: none;
}

.donor-stripe-container {
  border-radius: 16px;
  background: #f0f8ff;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 25px;
  align-self: stretch;
}

.donation-note-list-label-badge {
  border-radius: 16px;
  background: #e2edff;
  padding: 8px 16px;
  color: var(--cui-primary);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.appeals-price-point-card.active {
  display: flex;
  height: 98px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 2px solid var(--secondary, #279fff);
  background: #fff;
  box-shadow: 0px 4px 5px 0px rgba(101, 227, 255, 0.25), 0px 4px 5px 0px rgba(143, 235, 255, 0.5);
  color: #279fff;
  text-align: center;
  font-family: 'Coolvetica', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.999px;
  /* 79.993% */
  width: 130px;
  flex-direction: column;
  align-self: stretch;
  cursor: pointer;
}

.appeals-price-point-card {
  display: flex;
  height: 98px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 2px solid #d3d3d3;
  background: #fff;
  color: #929292;
  text-align: center;
  font-family: Coolvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.999px;
  /* 99.991% */
  width: 130px;
  flex-direction: column;
  align-self: stretch;
  cursor: pointer;
}

.appeals-cause-type-card.active {
  display: flex;
  padding: 6.268px 30.643px 8.75px 30.643px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 7.804px;
  flex: 1 0 0;
  border-radius: 11.143px;
  border: 2px solid var(--secondary, #279fff);
  background: #279fff;
  box-shadow: 0px 4px 5px 0px rgba(101, 227, 255, 0.25), 0px 4px 5px 0px rgba(143, 235, 255, 0.5);
  color: white;
  font-family: 'Coolvetica', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.142px;
  /* 92.849% */
  cursor: pointer;
}

.appeals-cause-type-card {
  display: flex;
  padding: 6.268px 30.643px 8.75px 30.643px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 7.804px;
  flex: 1 0 0;
  border-radius: 11.143px;
  border: 1.393px solid #d7d7d7;
  background: #fff;
  color: #d7d7d7;
  font-family: 'Coolvetica', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.142px;
  /* 92.849% */
  cursor: pointer;
}

.appeal-label-generated-ai {
  border-radius: 4px;
  background: #bde2ff;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--secondary, #279fff);
  font-family: 'Coolvetica', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 12.5px */
}

.appeal-card-title {
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--cui-primary);
  margin-bottom: 0;

  @media screen and (max-width: 769px) {
    font-size: 18px;
  }
}

.appeal-card {
  box-shadow: none;
  border-radius: 1rem;
  border: 1px solid #ccc !important;
}

.appeal-badge-light {
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Secondary, #279fff);
  opacity: 0px;
  background: #dff1ff;
  font-size: 1rem;
  color: #279fff;

  @media screen and (max-width: 769px) {
    font-size: 12px;
  }
}

.appeal-radio-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 9.28px 13.92px;
  gap: 0px;
  border-radius: 9.28px;
  border: 2px solid var(--Gray-Shades-Light-Gray, #e7e7e7);
  justify-content: space-between;
  color: #161616;
  background: #fbfbfb;

  &.active {
    background: var(--Status-Success-50, #e9f9ef);
    border: 2px solid var(--Status-Success-600, #1fb356);
  }

  &:hover {
    cursor: pointer;
  }

  .appeal-box-recent {
    position: inherit;
    row-gap: 5px;

    label {
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    small {
      font-family: Inter;
      font-size: 10px;
      font-weight: 400;
      line-height: 12.1px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .amount-donation {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
}

.appeal-button-live-event {
  width: auto;
  padding: 0.8rem 1rem;

  @media screen and (max-width: 769px) {
    width: 100%;
  }
}

.appeal-tabs-live-event {
  background-color: #D7E2FF;
  border: 0px solid transparent;
  border-radius: 100px;
  padding: 10px 0px;
  gap: 2.5%;
  justify-content: center;
  margin-bottom: 1rem;

  .nav-item {
    font-size: 12px;
    width: 47.5%;
    text-align: center;

    .nav-link {
      border-radius: 100px;
    }
  }
}

.appeal-card-modal-live-event {
  width: 100%;
  display: flex;
  align-items: start;
  gap: 1rem;

  .card-link {
    width: 50%;
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;

    .card-link {
      width: 100%;

      img {
        width: 35%;
      }
    }
  }
}

.appeal-prevoius-container {
  position: relative;

  .input-group-text,
  input {
    padding: 12px;
  }

  .input-group-text {
    background: transparent;
    border-right: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  input {
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  input:focus,
  input:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }

  .appeal-col-donor {
    display: flex;
    align-items: center;

    .donor-name {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
      letter-spacing: -0.04em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: 5px 0px;
    }

    p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      letter-spacing: -0.04em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #a8a6ac;
      margin: 5px 0px;
    }
  }
}

.appeal-btn-submit {
  display: flex;
  width: 200px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--cui-primary) !important;
  color: var(--white, #fff) !important;
  font-family: 'Coolvetica', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  border: none;
}

.appeal-btn-preview {
  display: flex;
  width: 200px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--white, #fff) !important;
  color: var(--cui-primary) !important;
  font-family: 'Coolvetica', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 4px;
  letter-spacing: 0.16px;
  border: 1px solid #003080 !important;
}

.appeal-upsell-amount-input {
  color: var(--secondary, #279fff) !important;
  font-family: 'Coolvetica', sans-serif !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  /* 100% */
}

.payments-card-list {
  display: flex;
  padding: 16px var(--spacing-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-24, 24px);
  border-radius: var(--radius-16, 16px);
  border: 1px solid #d4d4d4;
  background: #fff;
}

.payments-card-list-title {
  color: var(--black, #212121);
  font-family: Coolvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
}

.payments-card-list-desc {
  color: var(--black, #212121);
  font-family: SF Pro Display;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
}

.payment-card-list-btn-connect {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none;
  background: var(--cui-primary) !important;
  color: var(--white, #fff) !important;
  font-family: 'Coolvetica', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}

.payment-card-list-btn-disconnect {
  border-radius: 8px;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--danger-500, #f43f5e) !important;
  font-family: Coolvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  border: none;
  background: transparent !important;
  box-shadow: none !important;
}

.payments-card-list-image {
  width: 32px;
  height: 32px;
}

.pagination .page-item {
  cursor: pointer;
}

.price-points .row {
  margin-top: 15px;
  margin-left: 15px;
}

.appeal-fileupload-container {
  border-radius: 20.075px;
  border: 2.673px dashed var(--Grey-03, #e2e6ea);
  display: flex;
  height: 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 20.075px;
  border: 1.673px dashed var(--Grey-03, #e2e6ea);
  cursor: pointer;
}

.appeal-donation-feedback-socialmedia {
  border-radius: 20.075px;
  border: 1.673px solid var(--Grey-03, #e2e6ea);
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.donation-feedback-history-btn {
  color: var(--cui-primary);
  font-family: Coolvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  background-color: white;
  border: none;
}

.donation-feedback-history-item {
  border-radius: 10px !important;
  border: 1px solid #dcdcdc !important;
  background: #fcfcfc !important;
}

.donation-feedback-history-item .accordion-button {
  background-color: transparent !important;
}

.organization-search-div {
  display: flex;
  width: 337px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--dark-gray, #888);
  background: var(--white, #fff);
}

.organization-search-input {
  outline: none;
  text-decoration: none;
  color: var(--dark-gray, #888);
  font-family: SF Pro Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
}

.org-permission-card {
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  gap: 8px;
  border-radius: 15px;
  border: 1px solid var(--gray, #d1d1d1);
  background: var(--white, #fff);
  cursor: pointer;
}

.org-permission-card-enable {
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  gap: 8px;
  border-radius: 15px;
  border: 2px solid var(--cui-primary);
  background: #f1f6ff;
  cursor: pointer;
}

.org-permission-card-enable label {
  color: var(--cui-primary);
}

#myBalance {
  .balance-info {
    background-image: linear-gradient(to right,
        #a5f473,
        #b2f477,
        #bdf37b,
        #c7f380,
        #d1f386,
        #c6f594,
        #bdf6a1,
        #b5f7ae,
        #9ff7c7,
        #93f5dd,
        #94f0ed,
        #a0ebf6);
    height: 250px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .activity-details {
    background-color: white;
    padding: 15px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

#donationsPage {
  background-color: white !important;
  border-radius: 10px;
  padding: 10px;

  table {
    tr th {
      font-size: 13px;
    }

    tr td {
      font-size: 13px;
    }
  }
}

#donorsPage {
  background-color: white !important;
  border-radius: 10px;
  padding: 10px;

  table {
    tr th {
      font-size: 13px;
    }

    tr td {
      font-size: 13px;
    }
  }
}

#donationDetailsPage {
  .title-details {
    font-size: 12px;
    font-weight: 600;
  }
}

#donorDetailsPage {
  .card-div {
    // -webkit-box-shadow: 9px -9px 6px -11px rgba(0,0,0,0.68);
    // -moz-box-shadow: 9px -9px 6px -11px rgba(0,0,0,0.68);
    // box-shadow: 9px -9px 6px -11px rgba(0,0,0,0.68);
    background: white;
    padding: 14px;
  }
}

#campaignsPage {
  background-color: white !important;
  border-radius: 10px;
  padding: 10px;

  table {
    tr th {
      font-size: 13px;
    }

    tr td {
      font-size: 13px;
    }
  }
}

#campaignSubNav {
  border-bottom: none !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-box-shadow: -9px 10px 9px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -9px 10px 9px -13px rgba(0, 0, 0, 0.75);
  box-shadow: -9px 10px 9px -13px rgba(0, 0, 0, 0.75);

  li .nav-link {
    padding: 15px 15px 15px 15px;
    color: black !important;
    font-weight: 600;
    font-size: 13px;
  }

  li .nav-link.active,
  li .nav-link:hover {
    border-color: #7cb670;
  }
}

#campaignOption {
  .nav {
    background-color: #fafafa;
  }

  .nav .nav-link.active {
    background: #eeeeee !important;
    border-top-right-radius: 0px;
    border-radius: 0px;
    color: black;
  }

  .nav .nav-link:hover {
    color: black;
  }
}

#messagingCampaigns {
  .title-head {
    font-family: Didot, serif;
  }

  .action-dropdown {
    height: 30px;
  }

  .action-dropdown .dropdown-btn-name {
    background-color: #eee !important;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .action-dropdown .dropdown-btn-toggle {
    background-color: #eee !important;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}

.campaigns-teams-edit {
  background-color: #74d196d4;

  .form-check-input:checked {
    background-color: #29c77b;
    border-color: #29c77b;
  }
}

.default-page-with-table {
  background-color: white !important;
  border-radius: 10px;
  padding: 10px;

  table {
    tr th {
      font-size: 13px;
    }

    tr td {
      font-size: 13px;
    }
  }
}

.table thead tr th {
  background-color: #fcfcfd;
}

.table-header-color {
  color: #838a9b;
}

.price-point-search {
  display: flex;
  width: 100%;
  height: 44px;
  outline: none;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;

  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: var(--white, #fff);

  .price-point-search-input {
    font-family: 'Inter';
    outline: none;
    font-size: 14px;
  }
}

.password-change {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 0px 16px;
  margin-top: 12px;
  align-items: center;
  gap: 8px;

  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: var(--white, #fff);

  .password-change-input {
    font-family: 'SF Pro Display';
    font-size: 16px;
  }
}

.suggested-amount-content {
  p {
    outline: none;
    margin-top: 16px;
    text-decoration: none;
    color: #667085;
    font-family: 'SF Pro Display';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .widget-container {
    display: flex;
    justify-content: space-between;

    .widget-box {
      // max-height: 539px;
      margin-top: 24px;
      margin-right: 40px;
      border: 1px solid #d4d4d4;
      max-width: 406px;
      border-radius: 16px;
      padding: 32px;

      .give-once-or-monthly-tabs {
        margin-top: 10px;
        display: flex;
        width: 100%;

        .give-once-tab {
          border-color: #fff;
          border-width: 1px 0px 1px 1px;
          border-style: solid;
          border-radius: 10px 0px 0px 10px;
          display: flex;
          justify-content: center;
          //   @include flex-center(center, center);
          width: 50%;
        }

        .monthly-tab {
          //   @include flex-center(center, center);
          gap: 5px;
          display: flex;
          justify-content: center;
          border-color: #fff;
          border-width: 1px 1px 1px 0px;
          border-style: solid;
          border-radius: 0px 10px 10px 0px;
          width: 50%;
        }

        .give-once-tab,
        .monthly-tab {
          cursor: pointer;
          padding: 10px;
          background-color: #e7e7e7;
          color: #929292;
        }

        .give-once-tab.active,
        .monthly-tab.active {
          background-color: #003080;
          color: #fff;
        }
      }

      .donation-amounts-container {
        margin-top: 32px;

        p {
          outline: none;
          text-decoration: none;
          font-family: 'Epilogue', sans-serif;
          color: #9f9f9f;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          word-spacing: 1px;
          line-height: 16px;
        }
      }

      .currency-container {
        margin-top: 24px;
        position: relative;
        border: 1px solid #d4d4d4;
        border-radius: 16px;
        width: 100%;
        /* Updated */
        padding: 18px 18px;
        display: flex;
        align-items: center;

        p {
          margin-top: 16px;
          outline: none;
          text-decoration: none;
          color: #9f9f9f;
          font-family: SF Pro Display;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          word-spacing: 1px;
          line-height: 16px;
        }

        span:first-child {
          //   position: absolute;
          //   left: 5px;
          font-size: 16px;
          /* Adjust the font size as desired */
        }

        input {
          border: 0px;
          outline: none;
          color: #279fff;
          width: 90%;
          /* Updated */
          font-size: 32px;
          /* Adjust the font size as desired */
        }

        span:last-child {
          position: absolute;
          right: 15px;

          select {
            border: none;
            outline: none;
            font-size: 16px;
            color: #5a5e65;
          }
        }
      }

      #widget-preset-label {
        margin-top: 16px;
        outline: none;
        text-decoration: none;
        color: #9f9f9f;
        font-size: 13px;
        font-family: 'Epilogue', sans-serif;
        font-style: normal;
        font-weight: 400;
        word-spacing: 1px;
        line-height: 16px;
      }

      #widget-preset-description {
        margin-top: 16px;
        outline: none;
        text-decoration: none;
        color: #949494;
        font-size: 13px;
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 400;
        word-spacing: 1px;
        line-height: 24px;
      }

      .price-preset {
        display: flex;
        flex-wrap: wrap;

        .appeals-price-point-card {
          display: flex;
          height: 50px;
          margin: 4px;
          padding: 24px;
          justify-content: center;
          align-items: center;
          gap: 1px;
          border-radius: 16px;
          border: 1px solid #f0f0f0;
          background: #fff;
          color: #000;
          text-align: center;
          font-family: 'Coolvetica';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.999px;
          /* 99.991% */
          width: 98px;
          flex-direction: row;
          align-self: stretch;
          cursor: pointer;

          .price-amount {
            border: none;
            background-color: #fff;
            color: #000;
            width: 15px;
          }

          input {
            border: none;
            background-color: #fff;
            color: #000;
            width: 15px;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }

        .appeals-price-point-card.active {
          display: flex;
          height: 50px;
          margin: 4px;
          padding: 24px;
          justify-content: center;
          align-items: center;
          gap: 1px;
          border-radius: 16px;
          border: 1px solid #003080;
          background: #003080;
          color: #fff;
          text-align: center;
          font-family: 'Coolvetica';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.999px;
          /* 99.991% */
          width: 98px;
          flex-direction: row;
          align-self: stretch;
          cursor: pointer;

          .price-amount {
            border: none;
            background-color: #003080;
            color: #fff;
            width: 15px;
          }

          input {
            border: none;
            background-color: #003080;
            color: #fff;
            width: 15px;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
    }

    .price-point-box {
      margin-top: 24px;
      border: 1px solid #d4d4d4;
      width: 100%;
      border-radius: 16px;
      padding: 15px 32px 32px 32px;

      @media screen and (min-width: 800px) {
        .ppb-header {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }

        #ppb-title {
          text-align: left;
          padding-top: 8px;
        }
      }

      .ppb-header {
        align-items: center;
      }

      .ppb-index-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .ppb-selected-index {
          background-color: #003080;
          border-radius: 16px;
          height: 100%;
          // max-width: 193px;
          min-width: 90px;

          label {
            color: #fff;
            display: flex;
            justify-content: center;
            text-align: center;
            align-content: center;
            align-self: center;
            align-items: center;
            font-weight: 400;
            width: 100%;
            padding: 4.5px 24px;
            font-family: 'Coolvetica', sans-serif;
          }
        }
      }

      .pp-table {
        color: #667085;

        .pp-head {
          font-size: 12px;
          font-family: 'Coolvetica', sans-serif;
          font-weight: 300;
        }

        .pp-body {
          font-size: 14px;
          font-weight: 400;
          font-family: 'SF Pro Display', sans-serif;
        }
      }

      #ppb-title {
        font-family: 'Coolvetica', sans-serif;
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        color: #000;
      }

      #ppb-selected-count {
        font-family: 'Coolvetica', sans-serif;
        font-weight: 400;
        color: #003080;
        font-size: 16px;
      }

      .add-pp-btn {
        display: flex;
        width: 148px;
        height: 38px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--cui-primary) !important;
        color: var(--white, #fff) !important;
        font-family: 'Coolvetica', sans-serif !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.16px;
        border: none;
      }
    }
  }

  #sa-checkbox-description {
    margin-top: 16px;
    outline: none;
    text-decoration: none;
    color: #949494;
    font-size: 15px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    word-spacing: 1px;
    line-height: 24px;
  }

  .sa-hr-line {
    width: 100%;
    margin-top: 24px;
    height: 1px;
    background-color: #d4d4d4;
  }

  .chkcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-top: 24px;
    cursor: pointer;
    font-family: 'SF Pro Display';
    font-size: 14px;
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkbox-label {
      margin-top: 5px;
    }
  }

  .chkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .chkcontainer .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
  }
}

.crop-container {
  position: relative;
  width: 436px;
  height: 356px;

  background: linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 0,
    linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 100%,
    linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 0,
    linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 100%,
    linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 0 0,
    linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 100% 0,
    linear-gradient(to top, #cbd4e1 8px, transparent 8px) 0 100%,
    linear-gradient(to top, #cbd4e1 8px, transparent 8px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 70px 70px;
}

.mt-xsm-6 {
  margin-top: 16px;
}

.mt-xsm-10 {
  margin-top: 24px;
}

.mt-xsm-11 {
  margin-top: 26px;
}

.mt-xsm-12 {
  margin-top: 28px;
}

.mt-xsm-13 {
  margin-top: 30px;
}

.text-xsm {
  font-size: 11px !important;
}

.text-xsm-2 {
  font-size: 12px !important;
}

.text-xsm-3 {
  font-size: 13px !important;
}

.text-xsm-4 {
  font-size: 14px !important;
}

.text-xsm-6 {
  font-size: 16px !important;
}

.text-xsm-7 {
  font-size: 18px !important;
}

.text-xsm-8 {
  font-size: 20px !important;
}

.text-xsm-9 {
  font-size: 22px !important;
}

.text-xsm-10 {
  font-size: 24px !important;
}

.text-xmd-1 {
  font-size: 28px !important;
}

.text-xmd-3 {
  font-size: 32px !important;
}

.text-main-primary {
  color: $primaryColor !important;
}

.bg-light {
  background: white;
}

.bg-white {
  background: white;
}

.no-underline {
  text-decoration: none;
}

.fw-bold-400 {
  font-weight: 400;
}

.fw-bold-500 {
  font-weight: 500 !important;
}

.fw-bold-600 {
  font-weight: 600 !important;
}

.fw-bold-700 {
  font-weight: 700 !important;
}

.bg-dark-bluish-green {
  background-color: #357a87 !important;
}

.text-dark-bluish-green {
  color: #357a87 !important;
}

.text-menu-dark {
  color: #101828 !important;
}

.w-full {
  width: 100%;
}

.textarea-resize-none {
  resize: none;
}

.ratio-campaign-vertical {
  aspect-ratio: 0.8;
  object-fit: cover;
}

.ratio-campaign-horizontal {
  aspect-ratio: 1.47;
  object-fit: cover;
}

.w-campaign-vertical {
  max-width: 292px !important;
}

.w-campaign-horizontal {
  width: calc(50% - 0.5rem);

  >div {
    width: 100%;
    margin-top: 1rem !important;
  }
}

.croparea-campaign-vertical {
  aspect-ratio: 0.8;
  width: auto !important;
  height: 96% !important;
  border-radius: 0.5rem;
}

.croparea-custompage {
  aspect-ratio: 1;
  height: 300px !important;
  width: 300px !important;
  border-radius: 0.5rem;
}

.croparea-favicon {
  aspect-ratio: 1;
  width: auto !important;
  height: 96% !important;
  border-radius: 0.5rem;
}

.object-fit-cover {
  object-fit: cover;
}

.croparea-campaign-horizontal {
  aspect-ratio: 1.47;
  height: auto !important;
  width: 96% !important;
  border-radius: 0.5rem;
}

// Appeals Description RTE styles
.appeal-descript-editor {
  .ql-toolbar {
    border: 0;
    margin-bottom: 8px;
  }

  .ql-container {
    padding: 10px 14px;
    border: 1px solid var(--cui-gray-300) !important;
    border-radius: 8px;

    .ql-editor {
      padding: 0;
      min-height: 108px;
      max-height: 400px;
    }
  }
}

// fix various svg icon size on sidebar menu item
.simplebar-content .nav-item a.nav-link img.nav-icon {
  width: 16px;
  aspect-ratio: 1/1;
  object-position: center;
  object-fit: scale-down;
}

.cursor-pointer {
  cursor: pointer;
}

.upload-container {
  text-align: center;
  margin-top: 10px;
}

.upload-button {
  display: inline-block;
  background-color: #003080;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button input[type='file'] {
  display: none;
}

.upload-icon {
  margin-right: 5px;
}

.image-preview-container {
  margin: 10px;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.image-preview {
  position: relative;
}

.image-preview img {
  width: 150px !important;
  height: 120px !important;
}

.remove-icon {
  color: white;
  position: absolute;
  top: 0;
  right: 0;

  width: 28px !important;
  cursor: pointer;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-wrapper {
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-wrapper img {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.btn-dropdown {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  margin-left: auto;
  padding: 0.25em 0.25em;
  background-image: var(--cui-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--cui-accordion-btn-icon-width);
  transition: var(--cui-accordion-btn-icon-transition);
  box-shadow: none !important;
  border: 0;
  border-radius: 0.375rem;
}

.border-selected {
  border: 3px solid #003080 !important;
}

/* Media query for large screens (lg) */
@media (min-width: 992px) {
  .textEditor-sections {
    width: 25%;
    border-right: 1px solid rgb(225, 223, 223);
  }

  .textEditor-accordions {
    width: 75%;
  }

  .pagesList {
    width: 34% !important;
  }

  .menuStructure {
    width: 66% !important;
  }
}

#editor-container {
  height: 375px;
}

// Quill Editor Stylings

.ql-color .ql-picker-options [data-value='color-picker']:before {
  content: 'Pick Color';
}

.ql-color .ql-picker-options [data-value='color-picker'] {
  background: none !important;
  width: 100% !important;
  height: 25px !important;
  text-align: center;
  color: blue;
  text-decoration: underline;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value);
}

.calendar {
  --cui-calendar-table-cell-size: 30px;
}

.date-picker {
  .input-group {
    height: 58px;

    input {
      font-size: 12px;
    }
  }
}